/* ==========================================================================
	Footer
	========================================================================== */

.bg-footer {
	background: linear-gradient(90deg, darken($gray-darker, 10%), $gray-darker, darken($gray-darker, 10%));
}

.footer {
	@extend .bg-footer;
	align-items: center;
	border-top: solid .3rem $brand-primary;
	color: white;
	display: flex;
	font-size: $font-size-base;
	text-align: center;
	min-height: 25rem;
	padding: 0 0 50px 0;
	
	a {
		@extend .font-bold;
		color: lighten($brand-primary, 15%);
		&:hover {
			color: $brand-tierce;
		}
	}

	@media(min-width: $screen-sm-min) {
		text-align: left;
	}
}

.footer_address {
	line-height: 2;
}

.list-footer {
	
	>li {
		padding-bottom: 1rem;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 1rem;
		margin-top: 1rem;
		border-top: solid 1px $hr-border;
	}
}

.list-footer--logo {
	margin-top: 1rem;
	padding-top: 1rem;

	img {
		height: 2.5rem;
	}
}

.g-play__img {
	width: 9rem;
	margin-top: 1rem;
}
